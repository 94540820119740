import {PageUrlConstant} from './page-url.constant';

export class Module {
  KEY?: string;           // module_key
  PATH?: string;          // path of module (without ./) <used in webclient-routing>
  ICON_CLASS?: string;    // icon class (fontawesome 4.7) of module in side menu
  NAME?: string;          // translate key in "MENU.XXX"
  PARAMS?: any;           // Custom params to be passed into the module
  TO_BE_REPLACE_MODULE?: string;    // Module to be replaced by this current module (The base module that should not be displayed)
  imgUrl?: string;

  constructor(key?: string, path?: string, iconClass?: string, name?: string, params?: any, toBeReplacedModule?: string, imgUrl?: string) {
    this.KEY = key;
    this.PATH = path;
    this.ICON_CLASS = iconClass;
    this.NAME = name;
    this.PARAMS = params ? params : null;
    this.TO_BE_REPLACE_MODULE = toBeReplacedModule;
    this.imgUrl = imgUrl;
  }
}

/**
 * All module keys definition
 */
export const ModuleKeyDefinition = {
  // Base
  NEWS: 'news',
  CHAT: 'chat',
  CONTACT: 'contact',

  // Modules
  TRAINING: 'training_app',
  STORE_SCHEDULE: 'store_schedule',
  QUESTIONNAIRE: 'questionnaire_app',
  STORE_REPORT: 'store_report_app',
  JOB_DISPATCH: 'store_job_dispatch_app',
  CORPORATE_MATERIAL: 'corporate_material',
  WORKFLOW: 'team_workflow',
  VIDEO_STREAMING: 'video_streaming',
  WEBDAV_MATERIAL: 'webdav_material',
  MESSAGE_STAR: 'message_star',

  // Toggles
  GROUP_CREATE: 'group_app',
  MESSAGE_COMMENT: 'message_comment',
  WATERMARK: 'watermark_app',
  MESSAGE_EMOJI: 'message_emoji',
  MESSAGE_REPLY: 'message_reply',
  MESSAGE_EDIT: 'message_edit',
  MESSAGE_REPORT: 'message_report',
  OUT_OF_OFFICE: 'out_of_office',
  BROADCAST: 'broadcast',
  MESSAGE_EXPORT: 'message_export_app',
  CHAT_SECURITY: 'chat_security',
  ATTACHMENT_SAVE: 'attachment_save',
  MESSAGE_EXPORT_RAW: 'message_export_raw',
  CONTACT_DIAL: 'contact_dial',
  EXTERNAL_COPY: 'external_copy',
  USER_PIC_EDIT_PROHIBIT: 'user_pic_edit_prohibit',
  STICKER: 'sticker',
  NEWS_EMOJI: 'news_emoji',
};

export const ModuleKeyMapping = {
  webdav_material: 'file_sharing'
}

/**
 * All modules' menu configurations
 */
export const WebclientSideNavConfigurations: { [key: string]: Module } = {
  'news': new Module(ModuleKeyDefinition.NEWS, PageUrlConstant.WEBCLIENT.NEWS, 'newspaper-o', 'NEWS', null, null, 'assets/images/side_nav/news.png'),
  'chat': new Module(ModuleKeyDefinition.CHAT, PageUrlConstant.WEBCLIENT.CHAT, 'comment', 'CHATS', null, null, 'assets/images/side_nav/chat.png'),
  'contact': new Module(ModuleKeyDefinition.CONTACT, PageUrlConstant.WEBCLIENT.CONTACT, 'user', 'CONTACTS', null, null, 'assets/images/side_nav/contact.png'),
  'training_app': new Module(ModuleKeyDefinition.TRAINING, PageUrlConstant.WEBCLIENT.TRAINING, 'book', 'TRAINING'),
  'questionnaire_app': new Module(ModuleKeyDefinition.QUESTIONNAIRE, PageUrlConstant.WEBCLIENT.QUESTIONNAIRE, 'question-circle', 'QUESTIONNAIRE'),
  'store_report_app': new Module(ModuleKeyDefinition.STORE_REPORT, PageUrlConstant.WEBCLIENT.STORE_REPORT, 'edit', 'STORE_REPORT'),
  // "store_job_dispatch_app": new Module(ModuleKeyDefinition.JOB_DISPATCH, PageUrlConstant.WEBCLIENT.JOB_DISPATCH, "breiefcase", "JOB_DISPATCH"),
  'corporate_material': new Module(ModuleKeyDefinition.CORPORATE_MATERIAL, PageUrlConstant.WEBCLIENT.CORPORATE_MATERIAL, 'book', 'CORPORATE_MATERIAL', null, null, 'assets/images/side_nav/corporate_material.png'),
  'team_workflow': new Module(ModuleKeyDefinition.WORKFLOW, PageUrlConstant.WEBCLIENT.WORKFLOW, 'edit', 'WORKFLOW'),
  'video_streaming': new Module(ModuleKeyDefinition.VIDEO_STREAMING, PageUrlConstant.WEBCLIENT.VIDEO_STREAMING, 'video-camera', 'VIDEO_STREAMING'),
  'file_sharing': new Module(ModuleKeyDefinition.WEBDAV_MATERIAL, PageUrlConstant.WEBCLIENT.WEBDAV_MATERIAL, 'book', 'WEBDAV_MATERIAL', null, null, 'assets/images/side_nav/corporate_material.png'),
  'message_star': new Module(ModuleKeyDefinition.MESSAGE_STAR, PageUrlConstant.WEBCLIENT.MESSAGE_STAR, 'star-o', 'MESSAGE_STAR', null, null, 'assets/images/side_nav/message_star.png'),
  // TODO: link = store-schedule (not yet start)
  // "store_schedule": new Module("store_schedule", "", "", "calendar", "SCHEDULER")
};
