export const ZhChsChatroomStrings = {
  SETTINGS: "设定",
  PHOTO: "相片",
  VIDEO: "影片",
  DOCUMENT: "文件",
  MESSAGE_STAR: '星号信息',

  EXPORT_CHAT: {
    TITLE: "导出聊天讯息",
    CHAT: "聊天室: ",
    TIME: "时间: ",
    CHAT_INFO: "聊天室: ",
    EXPORT_TIME_PERIOD: "时间范围",
    RANGE_SELECT_MSG: "请选择时间范围，在此范围内的讯息将被导出。",
    NO_TIME_PERIOD_ERROR_MSG: "请选择时间范围",
    CONFIRM_MSG: "确定导出由 {{FROM}} 至 {{TO}} 的讯息？",
    IN_PROGRESS_MSG: "聊天讯息导出中，你可以在右上角的通知中心查看进度，你将在档案准备完成后收到通知。",
    EXPORT_DONE_DOWNLOAD: "完成，按此下载。",
    EXPORT_READY_MSG: "聊天讯息档案已准备完成，你可以在通知中心下载档案。",
    EXPORT_WITH_WATERMARK: {
      TITLE: "在附件上添加水印? ",
      TIP: "(如选择添加水印，只有相片会被导出。)"
    }
  },

  SEND: "传送",
  TEXTAREA_PLACEHOLDER: {
    IE: "输入信息 / 拖曳文件",
    OTHERS: "输入信息 / 拖曳文件 / 贴上图片",
    TEXT_ONLY: "输入信息",
    DROP_FILE_TIPS: "拖曳文件"
  },
  MESSAGE_OPTION: {
    UPLOAD: "上载",
    LOCATION: "地点",
    ATTACH: "附件",
    EXIT_EDIT: "退出编辑模式",
    IMPORTANT: "重要",
    SMS: "短讯",
    SELECT_FROM_DOCUMENT_SHARING: "从档案分享中选择"
  },

  WHISPER: "私讯",
  NAME_WHISPER_LABEL: "{{USER}} (私讯)",

  READ: "已阅",
  READ_GROUP: "已阅 {{NUM}}",

  EDITED: "已编辑",

  NEW_MESSAGE: {
    ONE: "新信息 ({{NUM}})",
    MULTIPLE: "新信息 ({{NUM}})"
  },
  UNREAD_MESSAGE_HEADER: "新信息",

  ONLINE: "在线",
  LAST_SEEN: "最后在线时间: ",
  NOT_EXIST: "聊天室不存在",
  JOIN_CHAT: "{{ACTOR}} 把 {{USERS}} 加入群组",
  LEAVE_GROUP: "{{USERS}} 已离开群组",
  ADMIN_CHANGE: {
    ONE: "{{USER}} 已成为群组管理员",
    MULTIPLE: "{{USERS}} 已成为群组管理员"
  },
  ADMIN_REMOVED: {
    ONE: "{{USER}} 已不再是群组管理员",
    MULTIPLE: "{{USERS}} 已不再是群组管理员"
  },
  MESSAGE_RECALLED: {
    ME: "你已收回一则信息",
    OTHERS: "{{USER}} 已收回一则信息"
  },

  CLASSIFICATION_LEVEL: {
    AUTH_TITLE: "您正在进入一个机密聊天室，讯息将在离开此聊天室时删除。请再次进行验证以接受并进入聊天室。",
    DESC: "信息分级: ",
    LEVEL0: "一般",
    LEVEL1: "内部", // origin: 限阅
    LEVEL2: "限阅" // origin: 机密
  },

  ENCRYPTED_MESSAGE: {
    AUTH_TITLE: "查看安全信息前请先进行验证",
    MESSAGE_PREVIEW: "安全信息，点击查看",
    STARRED_MESSAGE_PREVIEW: "安全信息，请在聊天室內点击查看"
  },

  OUT_OF_OFFICE: {
    UNTIL: "不在办公室直到 ",
    COUNT: {
      ONE: "{{NUM}} 人不在办公室",
      MULTIPLE: "{{NUM}} 人不在办公室"
    }
  },

  REPLY: {
    YOU: "您",
    IMAGE: "相片",
    VIDEO: "影片",
    AUDIO: "语音信息",
    DOCUMENT: "文件",
    LOCATION: "位置",
    STICKER: "贴图"
  },

  GROUP_INFO_ITEM: {
    ADMIN: "群组管理员",
    OUT_OF_OFFICE: "不在办公室"
  },

  MESSAGE_OPTIONS: {
    INFO: "信息详情",
    ACKNOWLEDGEMENT_INFO: '讯息回报详情',
    FORWARD: "转发",
    SELECT: "选择信息",
    DOWNLOAD: "下载",
    RECALL: "收回信息",
    REPLY: "回覆",
    EDIT: "編輯",
    REPORT: "回报信息",
    STAR: '标上星号',
    UNSTAR: '移除星号',
    COPY: '复制',
    EMOJI: '发送表情',
    DELETE: "删除"
  },

  EMOJI_INFO: {
    TITLE: "表情详情",
    PREVIEW_TITLE: "所有 {{emoji_count}} 个表情"
  },

  MESSAGE_INFO: {
    TITLE: "信息详情",
    READ_HEADER: "已读取",
    DELIVERY_HEADER: "已传送",
    UNREAD_HEADER: "未读取",
    ACKNOWLEDGED_HEADER: '已确认',
    SMS_HEADER: "短讯状态"
  },

  FORWARD: {
    CONFIRM_MSG: "确定转发信息到 {{chat}}?",
    SUCCESS_MSG: "信息已转发"
  },

  DELETE: {
    CONFIRM_MSG: "是否删除此信息?"
  },

  ACK: {
    ACKED: "已确认",
    ACK: "确认"
  },

  FALLBACK_STATUS: {
    PENDING: '等候传送',
    SENT: '已传送',
    ERROR: '发生错误'
  }

};
