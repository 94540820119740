import { EnLoginStrings } from "../login/strings/en-login.strings";
import { EnWebclientString } from "../webclient/strings/en-webclient.strings";
import { EnCustomFieldStrings } from "../webclient/shared/custom-field/strings/en-custom-field.strings";
import { EnDateStrings } from './date/en-date.strings';

export const WevdavStrings = {
  USERNAME_INPUT_TIP: 'Input your username',
  PASSWORD_INPUT_TIP: 'Input your username',
};

export const EnGeneralStrings = {
  TEAMNOTE: "TeamNote®",
  LANGUAGE: "Language",
  TERMS_AND_CONDITIONS: "T&C",
  LOGOUT: "Logout",

  POWER_BY_MSG: "Powered by TeamNote®",
  COPYRIGHT_MSG: "Copyright © 2024 TeamNote Limited. All Rights Reserved.",

  TERMS_TITLE: "Terms of Service",
  TERMS_ACCEPT: "ACCEPT",
  TERMS_DECLINE: "DECLINE",

  SYSTEM_ERROR: "Something went wrong. Please try again later.",
  RESOURCE_NOT_FOUND: "The target resource does not exist",

  FILE_TIP: "Supported formats: JPEG, JPG, PNG, PDF, DOC, XLS, PPT, MP4; Maximum size: {{SIZE}}MB",
  FILE_TIP_DYNAMIC: "Supported formats: {{FORMATS}}; Maximum size: {{SIZE}}MB",
  FILE_TYPE_ERROR: "Invalid file type",
  FILE_SIZE_ERROR: "File size too large",
  NO_FILE: "Please upload at least one valid file",
  SELECT_FILE: "Select file",
  SELECT_IMAGE: "Select image",
  SIGN_HERE: "Sign Here",

  FILE_OPEN_ERROR: "File cannot be opened",
  FILE_PDF_PASSWORD_NOT_SUPPORT: "This PDF is password protected, and therefore cannot be opened",
  FILE_PDF_PASSWORD_REQUIRED: "Please enter PDF password",
  FILE_PDF_PASSWORD_ERROR: "Invalid PDF password",

  NO_CONTENT: "No content exists yet",
  NO_PHOTO: "No photo exists yet",
  NO_VIDEO: "No video exists yet",
  NO_DOCUMENT: "No document exists yet",
  NO_KEYWORDS: "Enter keywords to find message",

  NOTIFICATION: "Notifications",
  NO_NOTIFICATION: "No Notifications",
  NEW_MESSAGE_NOTIFICATION: "New Message From {{CHAT}}",

  NEW_VERSION_MSG: "A new version is available. Please refresh this page.",
  SESSION_TIMEOUT_MSG: "Your session has expired. Please login again.",

  DONE: "Done",
  EDIT: "Edit",
  DELETE: "Delete",
  CANCEL: "Cancel",
  SAVE: "Save",
  BACK: "Back",
  CONFIRM: "Confirm",
  UPLOAD: "Upload",
  EXPORT: "Export",
  IMPORT: "Import",
  DOWNLOAD: "Download",
  BROWSE_CAPS: "BROWSE",
  REFRESH: "Refresh",
  ALL: "All",
  SUBMIT: "Submit",
  CLEAR: "Clear",
  OR: "Or",
  SEARCH: "Search",

  FROM: "From",
  TO: "To",
  PROCESSING: "Processing...",

  WEBSOCKET: {
    OFFLINE: 'You are already offline, reconnect in 1 seconds...',
  },

  DOWNLOADED_TIP: 'Document download successfully'
};

export const EnLoadingStrings = {
  LOADING: "Loading...",
  CONNECTING: "Connecting...",
  RECONNECTING: "Reconnecting...",
  LOADING_CONTACTS: "Loading contacts..."
};

export const EnUtilityStrings = {
  IMAGE_CAPTION_PLACEHOLDER: "Add a caption...",
  FILE_UPLOAD_DRAG_FILE: "Drag file here",

  CUSTOM_FIELD: EnCustomFieldStrings
};

export const EnLangStrings = {
  CANTONESE: "Cantonese",
  MANDARIN: "Mandarin"
};

export const EnString = {
  GENERAL: EnGeneralStrings,
  LOGIN: EnLoginStrings,
  LOADING: EnLoadingStrings,
  UTILITY: EnUtilityStrings,
  LANG: EnLangStrings,
  DATE: EnDateStrings,

  WEBCLIENT: EnWebclientString
};
