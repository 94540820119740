import { Injectable } from '@angular/core';
import { MessageDelivery } from '../../../../../models/message-delivery';
import { MessageRead } from '../../../../../models/message-read';
import { MessageEmoji } from '../../../../../models/message-emoji';
import { Emoji } from '../../../../../models/emoji';
import { MessageAck } from '../../../../../models/message-acknowledgement';
import { Message } from '../../../../../models/message';
import { MessageStar } from '../../../../../models/message-star';
import { MessageUpdate } from '../../../../../models/message-update';
import { BehaviorSubject } from 'rxjs';

import * as _ from 'lodash';
import { AccountManagerService } from '../../../account/account-manager.service';
import { UserContactService } from '../../user-contact/user-contact.service';
import { TeamnoteApiService } from '../../../../../api/teamnote-api.service';
import { MessageTypeConstant } from '../../../../../constants/message-type.constant';
import { TeamNoteApiConstant } from '../../../../../constants/api.constant';
import { EmojiService } from '../../../../../utilities/emoji/emoji.service';

@Injectable()
export class InfoMessageService {

  messageDeliveries: MessageDelivery[] = [];
  messageReads: MessageRead[] = [];
  messageEmojis: MessageEmoji[] = [];
  messageAcks: MessageAck[] = [];
  messageStars: MessageStar[] = [];
  messageUpdates: MessageUpdate[] = [];

  starredMsgsUpdate: MessageStar = null;
  starredMsgsUpdate$: BehaviorSubject<MessageStar> = new BehaviorSubject<MessageStar>(null);

  constructor(
    private _accountManagerService: AccountManagerService,
    private _userContactService: UserContactService,
    private _teamnoteApiService: TeamnoteApiService,
    private _emojiService: EmojiService
  ) { }

  initInfoMessages() {
    this.messageDeliveries = [];
    this.messageReads = [];
  }

  // ----- API -----
  getMessageDetailAPI(messageId: string, success: Function, failure: Function) {
    let url = TeamNoteApiConstant.MESSAGE.MESSAGE_DETAIL;
    let params = {
      message_id: messageId
    };
    this._teamnoteApiService.callApi(url, params, success, failure);
  }

  // ----- Read -----
  insertMessageReadStatus(mr: MessageRead): void {
    let messageIds = mr.body.split(' ');
    _.each(messageIds, (id) => {
      let r: MessageRead = {
        body: id,
        sent_by: mr.sent_by,
        timestamp: mr.timestamp,
        type: mr.type
      };
      if (!_.find(this.messageReads, r)) {
        this.messageReads.push(r);
      }
    });
  }

  getMessageReadsByMessageId(messageId: string): MessageRead[] {
    return _.filter(this.messageReads, (mr: MessageRead) => {
      return mr.body == messageId;
    });
  }

  getUnqiueEarliestMessageReadsByMessageId(messageId: string): any[] {
    let reads = this.getMessageReadsByMessageId(messageId);
    let groupByUser = _.groupBy(reads, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.sortBy(datas, 'timestamp');
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserId(data.sent_by),
        timestamp: data.timestamp
      };
    });
  }

  getReadByOtherCountByMessageId(messageId: string): number {
    let reads = this.getUnqiueEarliestMessageReadsByMessageId(messageId);
    let notMyself = _.filter(reads, (r) => {
      return r.sent_by !== this._accountManagerService.userId;
    });
    return notMyself.length;
  }

  checkIfMessageIsReadByMe(messageId: string): boolean {
    let reads = this.getUnqiueEarliestMessageReadsByMessageId(messageId);
    let myRead = _.filter(reads, (r) => {
      return r.sent_by === this._accountManagerService.userId;
    });
    return myRead.length > 0;
  }

  // ----- Delivery -----
  insertMessageDeliveryStatus(md: MessageDelivery): void {
    let messageIds = md.body.split(' ');
    _.each(messageIds, (id) => {
      let d: MessageDelivery = {
        body: id,
        sent_by: md.sent_by,
        timestamp: md.timestamp,
        type: md.type
      };
      if (!_.find(this.messageDeliveries, d)) {
        this.messageDeliveries.push(d);
      }
    });
  }

  getMessageDeliveriesByMessageId(messageId: string): MessageDelivery[] {
    return _.filter(this.messageDeliveries, (md: MessageDelivery) => {
      return md.body == messageId;
    });
  }

  getUnqiueEarliestMessageDeliveriesByMessageId(messageId: string): any[] {
    let deliveries = this.getMessageDeliveriesByMessageId(messageId);
    let groupByUser = _.groupBy(deliveries, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.sortBy(datas, 'timestamp');
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserId(data.sent_by),
        timestamp: data.timestamp
      };
    });
  }

  // ----- Emoji -----
  insertMessageEmojiStatus(me: MessageEmoji): void {
    let { content, message_id } = JSON.parse(me.body)

    let e: MessageEmoji = {
      message_id: message_id,
      content: this._emojiService.decodeUtf8(content),
      sent_by: me.sent_by,
      timestamp: String(me.timestamp),
      type: me.type
    };

    let currentEmojiRecord = _.find(this.messageEmojis, { message_id: e.message_id, sent_by: e.sent_by });

    switch (_.toInteger(e.type)) {
      case MessageTypeConstant.EMOJI:
        if (!currentEmojiRecord) {
          this.messageEmojis.push(e)
        } else {
          if (e.timestamp > currentEmojiRecord.timestamp) {
            // const index = _.indexOf(this.messageEmojis, currentEmojiRecord);
            // _.set(this.messageEmojis, index, e);

            Object.assign(currentEmojiRecord, e) // change the data of original address
          }
        }
        
        break;

      case MessageTypeConstant.UNEMOJI:
        if (!currentEmojiRecord) break;

        let deleteIndex = _.findIndex(this.messageEmojis, { message_id: e.message_id, sent_by: e.sent_by })
        if (deleteIndex !== -1) {
          this.messageEmojis.splice(deleteIndex, 1)
        }

        break;
    }

    // console.log(this.messageEmojis);
  }

  getMessageEmojisByMessageId(messageId: string): MessageEmoji[] {
    return _.filter(this.messageEmojis, (me: MessageEmoji) => {
      return me.message_id == messageId;
    });
  }

  getEmojiCounts(emojis: Emoji[]): any {
    let emoji_count = {}

    _.forEach(emojis, (e) => {
      if (!emoji_count[e.content]) {
        emoji_count[e.content] = 1
      } else {
        emoji_count[e.content] += 1
      }
    })

    return emoji_count
  }

  getEarliestMessageEmojisByMessageId(messageId: string): MessageEmoji[] {
    let emojisSent = this.getMessageEmojisByMessageId(messageId)
    let emojiRecordByUser = _.groupBy(emojisSent, 'sent_by');

    // safeguard for avoiding show duplicate emoji
    return _.map(emojiRecordByUser, (datas) => {
      // swap >> find the latest emoji sent record for each user and return it
      let sorted = _.orderBy(datas, ['timestamp'], ['desc']);
      
      return { ...sorted[0] }
    })
  }

  getComputedEmojiDisplay(message: Message): Emoji[] {
    let emojiCounts = this.getEmojiCounts(message.emojis)
    
    let emojis = _.map(message.emojis, (emo) =>{ 
      let emojiCount = _.find(emojiCounts, (count, key) => {
        return key === emo.content
      })

      return {
        ...emo,
        count: emojiCount ? emojiCount : 0
      }
    })

    return _.uniqBy(_.orderBy(emojis, ['timestamp'], ['desc']), 'content');
  }

  getMyEmoji(emojis): Emoji {
    return _.find(emojis, (e) => e.sent_by === this._accountManagerService.userId)
  }

  checkIfMeSentAnEmoji(message: Message): boolean | string {
    let sentEmojiByMe = this.getMyEmoji(message.emojis)
    // return sentEmojiByMe ? true : false
    return sentEmojiByMe ? sentEmojiByMe.content : false
  }

  checkIfSentEmojiIsDuplicate(message: Message, emojiStr: string): boolean {
    let sentEmojiByMe = this.getMyEmoji(message.emojis)
    return sentEmojiByMe.content === emojiStr
  }

  // ----- Message Acknowledgement -----
  insertMessageAckStatus(ma: MessageAck): void {
    let messageIds = ma.body.split(' ');
    _.each(messageIds, (id) => {
      let a: MessageAck = {
        body: id,
        sent_by: ma.sent_by,
        timestamp: ma.timestamp,
        type: ma.type
      };
      if (!_.find(this.messageAcks, a)) {
        this.messageAcks.push(a);
      }
    });
  }

  getMessageAcksByMessageId(messageId: string): MessageAck[] {
    return _.filter(this.messageAcks, (ma: MessageAck) => {
      return ma.body == messageId;
    });
  }

  getUnqiueEarliestMessageAcksByMessageId(messageId: string): any[] {
    let acks = this.getMessageAcksByMessageId(messageId);
    let acksByUser = _.groupBy(acks, 'sent_by');
    return _.map(acksByUser, (datas) => {
      let sortedAcks = _.sortBy(datas, 'timestamp');
      let data = sortedAcks[0];

      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserId(data.sent_by),
        timestamp: data.timestamp
      };
    });
  }

  checkIfMessageIsAcked(message: Message): boolean {
    let { mode } = message.parsedBody.acknowledgement
    let acks = this.getUnqiueEarliestMessageAcksByMessageId(message.message_id)

    let myAck = _.filter(acks, a => {
      return a.sent_by === this._accountManagerService.userId
    })

    switch (mode) {
      case 'first':
        return acks.length > 0 || myAck.length > 0
      case 'many':
        return myAck.length > 0
      case 'disabled':
        return false
    }
  }

  getDeliverByOtherCountByMessageId(messageId: string): number {
    let delivers = this.getUnqiueEarliestMessageDeliveriesByMessageId(messageId);
    let notMyDeliver = _.filter(delivers, (r) => {
      return r.sent_by !== this._accountManagerService.userId;
    });

    return notMyDeliver.length;
  }

  // ----- Message Star & Message Unstar -----
  insertMessageStarStatus(ms: MessageStar): void {
    let messageIds = ms.body.split(' ');
    _.each(messageIds, (id) => {
      let s: MessageStar = {
        body: id,
        sent_by: ms.sent_by,
        timestamp: ms.timestamp,
        type: ms.type
      };

      let msgStarRecord = _.find(this.messageStars, { body: s.body, sent_by: s.sent_by });

      switch (_.toInteger(s.type)) {
        case MessageTypeConstant.MESSAGE_STAR:
          if (!msgStarRecord) {
            this.messageStars.push(s)
          }

          break;
  
        case MessageTypeConstant.MESSAGE_UNSTAR:
          // has received the message unstar type message (131)
          // indacates that the server has been updated
          if (s.sent_by === this._accountManagerService.userId) {
            // check if need to refresh starred message list of chat or global
            this.starredMsgUpdatedSubject(s);
          }

          if (!msgStarRecord) return;
  
          let deleteIndex = _.findIndex(this.messageStars, { body: s.body, sent_by: s.sent_by })
          if (deleteIndex !== -1) {
            this.messageStars.splice(deleteIndex, 1)
          }

          break;
      }
  
    });
    // console.log(this.messageStars)
  }

  getMessageStarsByMessageId(messageId: string): MessageStar[] {
    return _.filter(this.messageStars, (ms: MessageStar) => {
      return ms.body == messageId;
    });
  }

  getUnqiueLatestMessageStarsByMessageId(messageId: string): any[] {
    let stars = this.getMessageStarsByMessageId(messageId);
    let groupByUser = _.groupBy(stars, 'sent_by');
    return _.map(groupByUser, (datas) => {
      let sorted = _.orderBy(datas, ['timestamp'], ['desc']);
      let data = sorted[0];
      return {
        sent_by: data.sent_by,
        user: this._userContactService.getUserContactByUserId(data.sent_by),
        timestamp: data.timestamp
      };
    });
  }

  checkIfMessageIsStarredByMe(messageId: string): boolean {
    let stars = this.getUnqiueLatestMessageStarsByMessageId(messageId);
    let myStar = _.filter(stars, (r) => {
      return r.sent_by === this._accountManagerService.userId;
    });
    return myStar.length > 0;
  }

  starredMsgUpdatedSubject(ms: MessageStar): void {
    this.starredMsgsUpdate = ms;
    this.starredMsgsUpdate$.next(this.starredMsgsUpdate);
  }

  // ----- Message Update -----
  insertMessageUpdateStatus(mu: any): void {
    let u: MessageUpdate = {
      body: JSON.parse(mu.body),
      sent_by: mu.sent_by,
      update_message_id: mu.update_message_id,
      timestamp: mu.timestamp,
      correlation_id: mu.correlation_id,
      type: mu.type
    };

    let targetMessageUpdate = _.find(this.messageUpdates, u);

    if (!targetMessageUpdate) {
      this.messageUpdates.push(u);
    } else {
      Object.assign(targetMessageUpdate, u) // change the data of original address
    }

    // console.log('messageUpdates', this.messageUpdates);
  }

  checkIfMessageIsUpdated(messageId: string): boolean {
    const updatedRecords = this.getMessageUpdatesByMessageId(messageId);
    return updatedRecords.length > 0
  }

  getMessageUpdatesByMessageId(messageId: string): MessageUpdate[] {
    return _.filter(this.messageUpdates, (mu: MessageUpdate) => {
      return mu.update_message_id == messageId;
    });
  }

  getUnqiueLatestMessageUpdateBodyByMessageId(messageId: string): any {
    let messageUpdates = this.getMessageUpdatesByMessageId(messageId);

    let sorted = _.orderBy(messageUpdates, ['timestamp'], ['desc']);

    return sorted[0].body;
  }

}
