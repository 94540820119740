export const TeamNoteLocalStorageKeyConstants = {
  PREFIX: 'TNWC.',
  COOKIES: {
    DOMAIN: 'DM',
    DEVICE_TOKEN: 'D',
    SESSION_TOKEN: 'S',
    USER_ID: 'UID',
    DISCONNECT_TIME: 'DT',
    MESSAGE_RECALL_PERIOD: 'MR',
    MESSAGE_EDIT_PERIOD: 'MD',
    LOGIN: {
      USERNAME: 'LIUN',
      PASSWORD: 'LIPW',
      CREDDOMAIN: 'LICD',
      FORGETPW: 'FGPW'
    },
    WEBDAV: {
      USERNAME: 'WDUN',
      PASSWORD: 'WDPW',
    },
    EXTERNAL_AUTH: {
      AUTH_TYPE: 'EAY',
      AUTH_NAME: 'EAN',
      OAUTH: {
        OAUTH_TOKEN: 'OAT'
      },
      SAML: {
        REDIRECTED: 'ESR',
      }
    },
    USER_NAME: 'UN'
  },
  USER_PRESERVE_COOKIES: {
    TNC_LAST_ACCEPT_TIME: 'TNCLAT',
    TNC_CUSTOM_LAST_UPDATE_TIME: 'TNCCLUT'
  },
  USER_CONFIG_COOKIES: {
    LANGUAGE: 'L',
    CHAT_LIST_SORTING: 'CS',
    CHAT_LIST_COLLAPSING: 'CC',
    NON_VISIBLE_CHAT: 'NVC',
    FAVOURITE_CHAT: 'FC',
    EMOJI: 'E',
    ROUTE: 'R',
    ROUTE_MODULE: 'RM',
    ROUTE_META_DATA: 'RMD',
    CORPORATE_MATERIAL_EBOOK_PAGE_NUMBERS: 'EBP'
  },
  SESSION: {
    DOMAIN: 'DM',
    PASSWORD: 'P',
    LOGIN_TIME: 'LT',
    REFRESH_TOKEN: 'RT',
  },
  SUPER_CONNECTOR: {
    AUTH_TOKEN: 'auth_token',
    DEVICE_TOKEN: 'device_token',
  },
};
