import { Attachment } from './attachment';
import { Emoji } from './emoji';
import { UserContact } from './user-contact';
import { MessageRead } from './message-read';
import { MessageAck } from './message-acknowledgement';
import { MessageDelivery } from './message-delivery';
import { MessageStar } from './message-star';
import { MessageEmoji } from './message-emoji';

export class Sender {
  pic: string;
  pic_thumbnail: string;
  name: string;
  user_id: string;
}

export class MessageMention {
  user_id: string;
  name: string;
  length: number;
  location: number;
}

export class Message {
  attachments: Attachment[];
  body: string;
  chat_ids: string[];
  comment_count: number;
  comment_parent_id: string;
  correlation_id: string;
  device_token: string;
  emoji_counts: {};         // key = emoji, value = number of [key]
  emojis: Emoji[];
  expiry_time: any;
  message_id: string;
  update_message_id?: string;
  received_by: string;
  sender: Sender;
  sent_by: string;
  submit_time: number;
  timestamp: string;
  type: number;
  is_read: boolean;
  comments: Message[];

  // Local Controls
  delivery: MessageDelivery[];
  read: MessageRead[];
  emojiRecord: MessageEmoji[];
  ack: MessageAck[];
  star: MessageStar[];
  isLocalRead: boolean;

  // for display
  isSentByMe: boolean;
  senderContact: UserContact;
  parsedBody: any;
  timeDisplay: string;
  isWhisper: boolean;
  whisperContact: UserContact;
  isReadByMe: boolean;
  isAcked: boolean;
  deliverByOtherCount: number;
  readByOtherCount: number;
  isSelected: boolean;
  isStarredByMe: boolean;
  isUpdated: boolean;
  isSentEmoji: boolean | string;
  emojiDisplay: Emoji[];

  // for news
  likeCount: any;
  likeAction: string;
  isLiked: boolean;
  isReplying: boolean;

  // vairant
  date: string;
  isRecalled: boolean;
  isNewMsgIndicator: boolean;

}