export const MessageTypeConstant = {
  QUEUE_BIND: 0,

  RESPONSE: 1,
  TEXT: 2,
  ATTACHMENT: 3,
  ACTION: 4,
  NEWS: 5,
  READ: 6,
  BULK_READ: 7,
  LOCATION: 8,
  VOTE: 9,
  STICKER: 11,

  MESSAGE_UPDATE: 31,

  MESSAGE_DELIVERY: 101,
  MESSAGE_ACKNOWLEDGEMENT: 111,

  EMOJI: 120,
  UNEMOJI: 121,

  MESSAGE_STAR: 130,
  MESSAGE_UNSTAR: 131,

  JOIN_CHAT: 201,
  LEAVE_CHAT: 202,
  CHANGE_CHAT_ADMIN: 203,

  MESSAGE_DELETE: 301
};
